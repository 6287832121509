<template>
  <v-container>
    <v-skeleton-loader type="table" v-if="!data_loaded"></v-skeleton-loader>
    <Table
      ref="table"
      :headers="headers"
      :items="expenses"
      :status="false"
      :delete_meg="'حذف المصروف ؟ '"
      :delete_loader="delete_loader"
      :delete_dialog="delete_dialog"
      @delete_row="delete_expense($event)"
      @edit_row="edit_row($event)"
      @close_delete="delete_dialog = false"
      @show_delete="delete_dialog = true"
      v-if="data_loaded"
    ></Table>
    <!--table -->

    <Form
      v-if="data_loaded"
      :title="title"
      ref="emForm"
      :form_obj="form_obj"
      :form_error="alerts.error"
      :form_success="alerts.success"
      :success_text="alerts.success_text"
      :error_text="alerts.error_text"
      @save_form="save_form()"
      :save_loading="save_loading"
      @reset_id="(expenses_id = null), (title = 'اضافة مصروف')"
      @close_snack="close_snack($event)"
    ></Form>
  </v-container>
  <!--/ container -->
</template>

<script>
import Table from "@/components/expenses/table";
import Form from "@/components/expenses/form";

export default {
  name: "expenses",
  data() {
    return {
      form_obj: {
        employee_id: null,
        company_id: null,
        type: "employee",
        amount: 0,
        payment_method: "cash",
        attachment: null,
        date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
      },
      delete_dialog: false,
      expenses_id: null,
      delete_loader: false,
      expenses: [],
      title: "اضافة مصروف",
      data_loaded: false,
      save_loading: false,
      alerts: {
        success: false,
        error: false,
        success_text: "",
        error_text: "",
      },
      headers: [
        {
          value: "type",
          text: "النوع",
          align: "center",
        },
        {
          value: "name",
          text: "الاسم",
          align: "center",
        },
        {
          value: "payment_method",
          text: "ظريقة الدفع",
          align: "center",
        },
        {
          value: "amount",
          text: "المبلغ",
          align: "center",
        },
        {
          value: "actions",
          text: "اجراءات",
          align: "center",
        },
      ],
    };
  },

  methods: {
    get_expenses() {
      this.$store
        .dispatch("public__request", {
          config: {
            url: "expenses",
            method: "get",
          },
        })
        .then((res) => {
          this.expenses = Object.assign([], res.data.data);
          this.data_loaded = true;
          console.log(res);
        })
        .catch((err) => {
          console.log(err.response);
        });
    },
    save_form() {
      this.save_loading = true;
      let formData = new FormData();
      Object.keys(this.form_obj).forEach((e, i) => {
        formData.append(e, !this.form_obj[e] ? "" : this.form_obj[e]);
      });

      this.$store
        .dispatch("public__request", {
          config: {
            url: this.expenses_id
              ? `update_expeneses/${this.expenses_id}`
              : `expenses`,
            method: "post",
          },
          data: formData,
        })
        .then((res) => {
          console.log(res);
          this.alerts.error = false;
          this.save_loading = false;
          this.alerts.success_text = "تم اضافة المصروف بنجاح";
          this.alerts.success = true;
          this.$refs.emForm.toggle_btn_form();
          this.get_expenses();
        })
        .catch((err) => {
          console.log(err.response);
        });
    },
    close_snack(ev) {
      if (!ev.prop) {
        this.alerts[ev.type] = false;
      }
    },
    edit_row(ev) {
      console.log(ev);
      this.title = "تعديل المصروف";
      Object.keys(ev.item).forEach((e) => {
        this.form_obj[e] = ev.item[e];
      });
      this.expenses_id = ev.item.id;
      this.$refs.emForm.fade_form();
    },
    delete_expense(expenses_id) {
      this.delete_dialog = false;
      if (expenses_id) {
        this.delete_loader = true;
        this.$store
          .dispatch("public__request", {
            config: {
              url: `expenses/${expenses_id}`,
              method: "delete",
            },
          })
          .then((res) => {
            this.alerts.success = true;
            this.alerts.success_text = "تم حذف الشركة";
            this.delete_loader = false;
            this.get_expenses();
            this.delete_dialog = false;
            if (this.$refs.emForm.toggle_form == true) {
              this.$refs.emForm.toggle_btn_form();
            }
          });
      }
    },
  },
  created() {
    this.get_expenses();
  },
  components: {
    Table,
    Form,
  },
};
</script>
